<template>
    <div class="home" v-if="token !== '' && !reload">
        <div class="wrapper" :class="{ mercedesbenz: companyShortage === 'mb', daimler: companyShortage === 'dt' }">
            <div id="header" v-if="companyShortage === 'dt'">
                <span id="logo">
                    <a href="index.html">
                        <img src="@/assets/daimler-logo.svg" alt="Daimler Logo" />
                    </a>
                </span>
            </div>
            <div class="content">
                <Disclaimer :is-open="showModalDaimler" @close="showModalDaimler = false" />
                <div class="heroinfo">
                    <h1 v-if="companyShortage === 'mb'"><Translate value="ShopPage.Title" /> {{ companyName }}</h1>
                    <div class="products">
                        <ProductCard
                            product="AudioEvent"
                            bookingButton
                            demoButton
                            @showDisclaimer="showModalDaimler = true"
                            v-if="getCompanyShortage() !== 'dt'"
                        />
                        <ProductCard
                            product="Webcast"
                            bookingButton
                            demoButton
                            @showDisclaimer="showModalDaimler = true"
                        />
                        <ProductCard
                            product="Theater"
                            inquiryButton
                            demoButton
                            @showDisclaimer="showModalDaimler = true"
                            v-if="getCompanyShortage() !== 'dt'"
                        />
                        <ProductCard product="Pro" inquiryButton demoButton @showDisclaimer="showModalDaimler = true" />
                    </div>
                    <div class="hcentered white">
                        <Translate value="Reachability.0" /><a class="white" href="mailto:da-team@meetyoo.de"
                            >da-team@meetyoo.de</a
                        ><br />
                        <Translate value="Reachability.1" /><br /><br />
                        <Translate value="Reachability.2" /><a
                            class="white"
                            target="_blank"
                            :href="`https://webcast.meetyoo.de/oidc/s/daimler/ubivent/commworld-${companyShortage}`"
                        >
                            Communication World</a
                        ><br />
                        <a class="white link" @click="changeLanguage" role="link">
                            <Translate value="ChangeLang" />
                        </a>
                    </div>
                </div>
            </div>
            <Footer className="home"></Footer>
        </div>
    </div>
    <Loader class="homescreen" v-else />
</template>

<script setup>
import { ref, computed, nextTick, onMounted } from "vue";
import Translate from "@/components/Translate.vue";
import { changeLang, getCompanyName, getCompanyShortage, getToken, setTitle } from "@/utilities/utility";
import Footer from "@/components/Footer.vue";
import ProductCard from "@/components/ProductCard.vue";
import Disclaimer from "@/components/Disclaimer.vue";
import Loader from "@/components/Loader.vue";

const showModalDaimler = ref(false);
const reload = ref(false);
const companyName = computed(() => getCompanyName());
const companyShortage = computed(() => getCompanyShortage());
const token = computed(() => getToken());

const changeLanguage = () => {
    reload.value = true;
    changeLang();
    nextTick(() => {
        reload.value = false;
    });
};

onMounted(() => {
    setTitle("Title.Home");
});
</script>

<style lang="scss" scoped>
.products {
    margin-bottom: 35px;
}

.homescreen {
    width: 100vw;
    height: 100vh;
}

#header {
    height: 100%;
    background: white;

    img {
        height: 4vh;
        min-width: 200px;
        width: auto;
    }
}

.link {
    cursor: pointer;
    text-decoration: underline;
}
</style>
