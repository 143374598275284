import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import RedirectView from "@/views/RedirectView.vue";
import SuccessView from "@/views/SuccessView.vue";

const routes = [
    {
        path: "/form/:type",
        name: "form",
        component: () => import("../views/FormView.vue"),
    },
    {
        path: "/order/start",
        name: "redirect",
        component: RedirectView,
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/success",
        name: "success",
        component: SuccessView,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
