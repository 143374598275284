import translations from "./translations.json";
import globals from "./globals.json";
import { getoidcUrl, login } from "./api";
import { nextTick } from "vue";
import { useRouter } from "vue-router";

export function translate(key) {
    let text = "";
    try {
        text = translations[key][globals.lang];
    } catch (error) {
        console.error(`No translation found for key: ${key}`);
        text = key;
    }
    return text;
}

export function getValueForMail(options, choosen) {
    if (!choosen) return choosen;
    try {
        let returnValue = [];
        options.forEach((option) => {
            if (typeof choosen === "string" && `${option.value}` === choosen) {
                returnValue.push(option.title ? translate(option.title) : option.label);
            } else if (typeof choosen !== "string" && choosen.includes(option.value)) {
                returnValue.push(option.title ? translate(option.title) : option.label);
            }
        });
        return returnValue.length > 1 ? returnValue : returnValue[0] || choosen;
    } catch (e) {
        console.error(`Error in getValueForMail: ${e}`);
        return choosen;
    }
}

export function translateForMail(key) {
    let text = "";
    try {
        text = translations[key]["en" /*Here you can choose in which lang the PM gets the Mail*/];
    } catch (error) {
        text = key;
    }
    return text.replace(/ /g, "_");
}

export function changeLang() {
    if (globals.lang === "en") {
        globals.lang = "de";
        localStorage.setItem("lang", "de");
    } else {
        globals.lang = "en";
        localStorage.setItem("lang", "en");
    }
}

export function getLang() {
    return globals.lang;
}

export function getCompanyName() {
    return globals.companyName;
}

export function initCompany() {
    if (window.location.hostname.includes("daimler")) {
        globals.companyName = "Daimler Truck";
        globals.division = "daimlertrucksorder";
        globals.companyShortage = "dt";
        globals.host = `${window.location.origin}/ordersite/api`;
    } else if (window.location.hostname.includes("mercedes-benz")) {
        globals.companyName = "Mercedes-Benz";
        globals.division = "mercedesbenzorder";
        globals.companyShortage = "mb";
        globals.host = `${window.location.origin}/ordersite/api`;
    } else {
        //globals.companyName = "Daimler Truck";
        globals.companyName = "Mercedes-Benz";
        //globals.companyShortage = "dt";
        globals.companyShortage = "mb";
        globals.division = "orderlocal";
        globals.host = "https://192.168.111.51:25443";
    }
}

export function setTitle(title) {
    document.title = translate(title);
}

export function getDivision() {
    return globals.division;
}

export function getCompanyShortage() {
    return globals.companyShortage;
}

export function getTodayDate() {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    day = `${day}`.length < 2 ? `0${day}` : day;
    month = `${month}`.length < 2 ? `0${month}` : month;
    year = `${year}`.substring(2);
    return `${day}.${month}.${year}`;
}

export function parseDateToTimestamp(dateString) {
    const [day, month, year] = dateString.split(".");
    const date = new Date(year, month - 1, day);
    const timestamp = date.getTime();
    return timestamp;
}

export function parseTimeToTimestamp(timeString) {
    const today = new Date();
    const [hours, minutes] = timeString.split(":").map(Number);
    today.setHours(hours);
    today.setMinutes(minutes);
    const timestamp = today.getTime();
    return timestamp;
}

export function setRedirected(value) {
    globals.redirected = value;
}

export function getRedirected() {
    return globals.redirected;
}

export function initSite() {
    initCompany();
    initLang();
}

export async function checkLogin(redirected, token) {
    const router = useRouter();

    // if user is not redirected, start login process
    if (!location.href.includes("/order/start") && !redirected && token === "") {
        initSite();
        getoidcUrl();
        // when user is redirected, continue login process
    } else if (token === "") {
        initSite();
        const code = new URLSearchParams(location.href.split("?")[1]).get("code");
        const userInfo = await login(code);
        // if login worked store the token
        if (userInfo) {
            setToken(userInfo);
            await nextTick(() => {
                router.push("/");
            });
            // if login didn't work restart the login process
        } else {
            console.error(`Login not possible with code: ${code}`);
            getoidcUrl();
        }
    }
}

export function setToken(token) {
    globals.token = token;
    let newUI = parseJwt(token);
    newUI.provisioning = parseProvisioning(newUI.provisioning);
    globals.userInfo = newUI;
}

export function getUserInfo() {
    return globals.userInfo;
}

export function getHost() {
    return globals.host;
}

export function getToken() {
    return globals.token;
}

function initLang() {
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
        globals.lang = storedLang;
    }
}

function parseJwt(token) {
    try {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error(`Error in parseJwt: ${e}`);
    }
}

function parseProvisioning(provisioning) {
    try {
        let newEntrys = {};
        let entrys = provisioning.split("CorpDirEntry [")[1].split(",");
        entrys[entrys.length - 1] = entrys[entrys.length - 1].slice(0, -1);
        for (let i = 0; i < entrys.length; i++) {
            const splittedEntry = entrys[i].trim().split("=");
            newEntrys[splittedEntry[0]] = splittedEntry[1];
        }
        return newEntrys;
    } catch (e) {
        console.error(`Error in parseProvisioning: ${e}`);
    }
}
