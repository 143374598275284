<template>
    <div>
        <Loader />
    </div>
</template>

<script setup>
import { onMounted } from "vue";
import { setRedirected } from "@/utilities/utility";
import Loader from "@/components/Loader.vue";

onMounted(() => {
    setRedirected(true);
});
</script>

<style lang="scss" scoped></style>
