<template>
    <span>
        {{ text }}
    </span>
</template>

<script setup>
import { translate } from "@/utilities/utility";
import { computed, defineProps } from "vue";

const props = defineProps({
    value: { type: String, required: true },
});

const text = computed(() => translate(props.value));
</script>

<style lang="scss" scoped></style>
