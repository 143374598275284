import { getDivision, getHost, translate } from "./utility";

export async function getoidcUrl() {
    const division = getDivision();

    fetch(`${getHost()}/ordersite-backend/v1/api/start/${division}`, {
        method: "GET",
        mode: "cors",
        redirect: "manual",
    })
        .then((resp) => {
            location.href = resp.url;
        })
        .catch((err) => {
            console.error(`Error while starting login: ${err}`);
            alert(translate("Error.Login"));
            location.reload();
        });
}

export async function login(code) {
    let returnValue = undefined;

    await fetch(
        `${getHost()}/ordersite-backend/v1/api/login?${new URLSearchParams({
            code: code,
        })}`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${code}`,
            },
        }
    )
        .then((resp) => resp.text())
        .then((data) => (returnValue = data))
        .catch((err) => {
            console.error(`Error while login: ${err}`);
            alert(translate("Error.Login"));
            location.reload();
        });

    return returnValue;
}

export async function sendOrder(token, productName, email, values) {
    let returnValue = false;

    await fetch(
        `${getHost()}/ordersite-backend/v1/api/order?${new URLSearchParams({
            productName: productName,
            email: email,
        })}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values),
        }
    )
        .then((resp) => {
            if (resp) returnValue = true;
        })
        .catch((err) => {
            console.error(`Error while sending order: ${err}`);
        });

    return returnValue;
}
