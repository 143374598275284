<template>
    <div class="loading-component">
        <div class="loadingScreen">
            <div class="logo">
                <span class="logo__figure figure">
                    <span class="figure__head"></span>
                    <span class="figure__arm figure__arm--left"></span>
                    <span class="figure__arm figure__arm--right"></span>
                    <span class="figure__stomp"></span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingComponent",
};
</script>

<style scoped lang="scss">
.loading-component {
    width: 100%;
    //max-width: calc((100vh - 60px - 35px) * 16 / 9);
    max-width: calc((100vh - 60px) * 16 / 9);
    //height: calc(100vh - (60px + 35px));
    height: calc(100vh - 60px);
    z-index: 30000;
    position: absolute;
    bottom: 60px;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin: auto;
    left: 0;
    right: 0;

    @media screen and (max-width: 900px) {
        top: 48px;
    }

    .loadingScreen {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 5000;
        height: 0;
        width: 100%;
        padding-bottom: 5%;
        //max-width: calc((100vh - 60px - 35px) * 16 / 9);
        max-width: calc((100vh - 60px) * 16 / 9);

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            height: 2px;
            background-color: inherit;
            width: 100%;
        }

        &::before {
            top: 0;
            transform: translateY(-100%);
        }

        &::after {
            bottom: 0;
            transform: translateY(100%);
        }

        .logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .figure {
            position: relative;
            display: block;
            z-index: 2;
            animation-iteration-count: infinite;
            animation-delay: 1.3s;
            animation-direction: alternate;
            animation-fill-mode: forwards;

            > * {
                background-color: #61de00;
            }

            &__head {
                position: absolute;
                top: calc(50% + 12px);
                left: calc(50% + 2px);
                transform: translate(-50%, -50%);
                animation: head ease-in-out 1s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-fill-mode: forwards;
                border-radius: 4px;
            }

            &__arm {
                position: absolute;
                top: calc(50% + 24px);
                height: 8px;
                width: 0;
                transform: translate(-50%, -50%);

                &--left {
                    left: calc(50% - 10px);
                    transform: translate(-50%, -50%);
                    animation: arm_left ease-out 1s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-fill-mode: forwards;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &--right {
                    left: calc(50% + 14px);
                    transform: translate(-50%, -50%);
                    animation: arm_right ease-out 1s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-fill-mode: forwards;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            &__stomp {
                top: calc(50% + 33px);
                height: 24px;
                width: 8px;
                left: calc(50% + 2px);
                border-radius: 12.5px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                position: absolute;
                transform: translate(-50%, -50%);
                animation: stomp ease-in 1s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-fill-mode: forwards;
            }
        }

        @keyframes head {
            0% {
                top: calc(50% + 29px);
                height: 0;
                width: 0;
            }

            25% {
                height: 8px;
                width: 8px;
                top: 50%;
            }

            40% {
                top: calc(50% + 21px);
            }

            65% {
                top: calc(50% + 11px);
                height: 12px;
                width: 8px;
            }
            80% {
                top: calc(50% + 11px);
                height: 12px;
                width: 8px;
            }
            100% {
                top: calc(50% + 11px);
                height: 12px;
                width: 8px;
            }
        }

        @keyframes stomp {
            0% {
                height: 0;
                width: 0;
                top: 50%;
                border-radius: 50%;
                top: calc(50% + 33px);
                left: calc(50% + 2px);
            }

            25% {
                height: 28px;
                width: 6px;
            }

            30% {
                height: 20px;
                width: 12px;
                border-radius: 50%;
            }

            35% {
                top: calc(50% + 33px);
                height: 24px;
                width: 8px;
                left: calc(50% + 2px);
            }

            50% {
                top: calc(50% + 32px);
                height: 24px;
                width: 8px;
                left: calc(50% + 2px);
            }

            80% {
                top: calc(50% + 32px);
                height: 24px;
                width: 8px;
                left: calc(50% + 2px);
            }

            100% {
                top: calc(50% + 32px);
                height: 24px;
                width: 8px;
                left: calc(50% + 2px);
            }
        }

        @keyframes arm_left {
            0% {
                left: 50%;
                width: 0;
            }
            40% {
                left: 50%;
                width: 0;
            }
            80% {
                left: calc(50% - 9px);
                width: 19px;
            }
            100% {
                left: calc(50% - 9px);
                width: 19px;
            }
        }

        @keyframes arm_right {
            0% {
                left: 50%;
                width: 0;
            }
            40% {
                left: 50%;
                width: 0;
            }
            80% {
                left: calc(50% + 13px);
                width: 19px;
            }
            100% {
                left: calc(50% + 13px);
                width: 19px;
            }
        }
    }
}
</style>
