<template>
    <router-view />
</template>

<script setup>
import { watch, computed, onMounted } from "vue";
import { getToken, getRedirected, checkLogin } from "./utilities/utility";

const token = computed(() => getToken());
const redirected = computed(() => getRedirected());

watch([token.value, redirected.value], ([newToken, newRedirected]) => {
    checkLogin(newRedirected, newToken);
});

onMounted(() => checkLogin(redirected.value, token.value));
</script>

<style lang="scss">
@import "@/styles/forms.css";
@import "@/styles/modal.css";
@import "@/styles/style.css";
</style>
